<template>
  <section>
    <template v-if="value && !loading">
      <Component
        :is="componentsMap[type]"
        v-show="value.items.length"
        :value="value"
        :size="size"
        :hide-header="hideHeader"
        :show-all="showAll"
      >
        <template #preview>
          <slot name="preview" />
        </template>
      </Component>
      <slot v-if="!value.items.length" name="empty">
        <UiContainer>
          <p class="text-sm text-text-primary">
            Подходящих событий не найдено. Попробуйте сбросить фильтр<br />или выбрать другие
            параметры
          </p>
        </UiContainer>
      </slot>
    </template>
    <template v-else>
      <CompilationWidgetSkeleton :type="type" />
    </template>
  </section>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue'
import { type Compilation } from '@/5_entities/Compilation'
import { UiContainer } from '@/6_shared/ui'
import CompilationWidgetSkeleton from './CompilationWidgetSkeleton.vue'

type PropType = {
  value?: Compilation
  loading?: boolean
  type?: 'list' | 'masonry'
  size?: 'small' | 'big'
  hideHeader?: boolean
  showAll?: boolean
}

withDefaults(defineProps<PropType>(), {
  value: undefined,
  loading: false,
  type: 'list',
  size: 'big',
  hideHeader: false,
  showAll: false
})

const CompilationList = defineAsyncComponent(async () => {
  const { CompilationList } = await import('@/5_entities/Compilation')

  return CompilationList
})
const CompilationMasonry = defineAsyncComponent(async () => {
  const { CompilationMasonry } = await import('@/5_entities/Compilation')

  return CompilationMasonry
})

const componentsMap: Record<
  NonNullable<PropType['type']>,
  typeof CompilationList | typeof CompilationMasonry
> = {
  list: CompilationList,
  masonry: CompilationMasonry
}
</script>
